import React, { useCallback } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { bgNumberRegex } from '../../data/validationsRegex';
import { settings } from '../../settings';
import { sendServiceInquiry } from '@a1-ict/fsbo/generalSlice';

const ServiceInquiryForm = ({ serviceType, serviceId }) => {
  const dispatch = useDispatch();
  const { secure } = usePrivateFunction();

  const handleSubmit = useCallback(
    (e) => {
      const { name, email, phone, description } = e.target;
      e.preventDefault();

      if (phone && !phone.value.match(bgNumberRegex)) {
        toast.error('Моля въведете валиден телефонен номер', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }

      handleRequest(
        {
          name: name.value,
          email: email.value,
          phone: phone.value,
          subject: subject.value,
          description: description.value,
        },
        e.target
      );
    },
    [secure]
  );

  const handleRequest = async (data, form) => {
    secure(async () => {
      const result = await dispatch(
        sendServiceInquiry({ dispatch, settings, serviceType, serviceId, body: data })
      );
      if (result?.payload?.ok) {
        form.reset();
        toast.success('Успешно изпратено запитване!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        close();
      } else
        toast.error(result?.error?.message || 'Възникна грешка! Моля опитайте по-късно!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="d-flex flex-column flex-sm-row  mb-2 ">
        <Input
          className="mr-0 mr-sm-1  mb-2 mb-sm-0 "
          type="text"
          name="name"
          id="name"
          placeholder="Вашето име"
          required=""
        />
        <Input
          className="ml-0 ml-sm-1 "
          type="email"
          name="email"
          id="email"
          placeholder="E-mail"
          required=""
          title="Пример: office@fsbo.bg"
        />
      </FormGroup>
      <FormGroup className="d-flex flex-column flex-sm-row  mb-2 ">
        <Input
          type="text"
          name="phone"
          id="phone"
          placeholder="Телефон за обратна връзка"
          required=""
          className="mr-0 mr-sm-1  mb-2 mb-sm-0"
        />
        <Input
          className="ml-0 ml-sm-1"
          type="text"
          name="subject"
          id="subject"
          placeholder="Относно"
          required=""
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="textarea"
          name="description"
          id="description"
          placeholder="Съобщение"
          required=""
        />
      </FormGroup>
      <button className="w-100 btn animated mt-2">
        <span>Изпрати</span>
      </button>
    </Form>
  );
};

export default ServiceInquiryForm;
